import React, { FC, useCallback, useEffect } from 'react';
import { Button } from '@mui/material';
import { IOSView } from 'react-device-detect';
import {doc} from "prettier";

export interface NotificationSnackbarProps {
  state: string;
  onClick: (state: string) => void;
}

const SnackbarNotification: FC<NotificationSnackbarProps> = (props) => {
  const {
    state = 'Enter',
    onClick,
  } = props;

  const clickHandler = useCallback(() => {
    if (state === 'Enter') {
      onClick('Exit');
      window.parent.dispatchEvent(new CustomEvent('fullscreenIn'));
    }
    if (state === 'Exit') {
      onClick('Enter');
      window.parent.dispatchEvent(new CustomEvent('fullscreenExit'));
    }
  }, [state]);

  return (
    <Button
      onClick={clickHandler}
      variant="text"
      color={state === 'Enter' ? 'success' : 'error'}
    >
      {state}
      {' '}
      full screen
    </Button>
  );
};

export default SnackbarNotification;
