import { FC, useMemo } from 'react';
import { Box } from '@mui/material';
import { Participant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import classNames from './UnionModal.module.css';
import UserConfig from './UserConfig';
import { AudioContextType } from '../../context/AudioProvider/AudioProviderType';

export interface UserListProps {
  participants: Participant[];
  participantsStatus: AudioContextType['participantsStatus'];
  addIsSpeakingListener: AudioContextType['addIsSpeakingListener'];
  onKickParticipant: (item: Participant) => void;
  isAdmin: boolean;
  modalUserName: string;
}

const UserList: FC<UserListProps> = (props) => {
  const {
    participants,
    isAdmin = false,
    participantsStatus,
    onKickParticipant = () => undefined,
    modalUserName = '',
    addIsSpeakingListener,
  } = props;

  const hasParticipant = useMemo(() => {
    if (VoxeetSDK.conference.current !== null) {
      // eslint-disable-next-line array-callback-return
      return participants.filter((value) => {
        if (value.status !== 'Left' && value.status !== 'Kicked') {
          return value;
        }
      });
    }
    return [];
  }, [participants]);

  return (
    <Box className={classNames.userList}>
      {hasParticipant.map((participant) => (
        <UserConfig
          addIsSpeakingListener={addIsSpeakingListener}
          isAdmin={isAdmin}
          onKickParticipant={onKickParticipant}
          participantsStatus={participantsStatus}
          participant={participant}
          userName={VoxeetSDK.session.participant && VoxeetSDK.session.participant.id === participant.id ? modalUserName : participant.info.name || ''}
          key={participant.id}
        />
      ))}
    </Box>
  );
};

export default UserList;
