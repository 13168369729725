import { FC, memo, useEffect, useMemo, useState } from 'react';
import {
  Chat, Channel, MessageInput, MessageList, Window, CustomClasses,
} from 'stream-chat-react';
import { DefaultGenerics, StreamChat, Channel as ChannelType } from 'stream-chat';
import { useWindowSize } from 'react-use';
import Modal from '../../components/Modal/Modal';
import ModalHeader from '../../components/Modal/ModalHeader/ModalHeader';
import classNames from './ChatModal.module.css';
import 'stream-chat-react/dist/css/index.css';
import MessageInputComponent from './components/MessageInput/MessageInput';
import MessageComponent from './components/Message/Message';
import { devices } from '../../types/devices';

export interface ChatModalProps {
  onClose: () => void;
  isOpen: boolean;
  chatClient?: StreamChat<DefaultGenerics> | null;
  channelClient?: ChannelType<DefaultGenerics> | null;
  isDisconnect?: boolean;
}

const ChatModal: FC<ChatModalProps> = memo((props) => {
  const {
    onClose = () => undefined,
    isOpen = false,
    chatClient,
    channelClient,
    isDisconnect = true,
  } = props;

  const { width, height } = useWindowSize();
  const [device, setDevice] = useState<string>(devices.desktop);

  useEffect(() => {
    if (width > 1300) setDevice(devices.desktop);
    else setDevice(devices.mobile);
  }, [width]);

  const customClasses: CustomClasses = {
    chat: classNames.chat,
    channel: classNames.channel,
    messageList: classNames[`messageList_${device}`],
    message: classNames.message,
  };

  const heightModal = useMemo(() => {
    if (width > 1300) return '630px';
    if (width < 850) return '450px';
    if (width < 915) return '550px';
    return `${height}px`;
  }, [width, height]);

  return (
    <div>
      <Modal
        height={heightModal}
        width={width > 1300 ? '410px' : `${width - 8}px`}
        isOpen={isOpen}
        position={{
          bottom: width > 1300 ? '108px' : '0',
          left: width > 1300 ? '44px' : '0',
          right: 'auto',
          top: 'auto',
        }}
      >
        <div style={{ height: width > 1320 ? '500px' : `${height}px` }}>
          <ModalHeader
            title="Meeting Chat"
            onClose={onClose}
          />
          {isDisconnect ? <div>load...</div>
            : <>
              <div className={classNames.divider} />
              <Chat
                customClasses={customClasses}
                client={chatClient as StreamChat}
              >
                <Channel channel={channelClient as ChannelType<DefaultGenerics>}>
                  <Window>
                    <MessageList
                      hasMore
                      disableDateSeparator
                      Message={MessageComponent}
                    />
                    <div className={classNames.divider} />
                    <MessageInput Input={MessageInputComponent} />
                  </Window>
                </Channel>
              </Chat>
            </>}
        </div>
      </Modal>
    </div>
  );
});

export default ChatModal;
