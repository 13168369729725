import * as Three from 'three';
import { Component as EngineComponent, ComponentOptions } from '../../engine/Component';
import { VideoController, VideoSourceType } from '../services/VideoController';
import VideoVariable, { VideoVariableValueType } from '../network/variables/VideoVariable';
import NetworkObjectComponent from '../../engine/components/NetworkObject.component';
import VideoSoundDist from '../../assets/json/videos_sound_dist.json';
import { forDistRayCast } from '../../constans/raycast';

export type VideoTextureComponentOptions = ComponentOptions & {
  data?: {
    targetMesh?: Three.Mesh;
    source?: VideoSourceType;
  };
};

export default class VideoTextureComponent extends EngineComponent {
  public controller: VideoController;

  public source?: VideoSourceType;

  public prevVideoState?: VideoVariableValueType;

  public targetMesh: Three.Mesh | null = null;

  public enabled = true;

  public distanceVolume = 15;

  public raycastArray: any[] = [];

  static get code(): string {
    return 'videotexture';
  }

  constructor(options: VideoTextureComponentOptions) {
    super(options);
    this.controller = new VideoController();
    // this.controller.createElement();
    this.targetMesh = options.data?.targetMesh || this.targetMesh;
    if (!this.targetMesh) {
      this.targetMesh = this.getMeshFromEntity();
    }
    if (options.data?.source) this.source = { ...options.data?.source };
    // this.prevVideoState = this.videoState;
    if (this.targetMesh?.name) this.distanceVolume = VideoSoundDist[this.targetMesh.name as keyof typeof VideoSoundDist] || 15;
    return this;
  }

  public init() {
    return this.controller.createElement().then(() => {
      let result = Promise.resolve();
      if (this.source) result = this.setSource(this.source);
      return result;
    }).then(() => {
      this.setUpTexture();
    });
  }

  protected get timestamp() {
    return Math.floor(Date.now() / 1000);
  }

  public get videoState() : VideoVariableValueType {
    return {
      isPlaying: this.controller.isPlaying(),
      source: this.source,
      videoTime: this.controller.time,
      localTime: this.timestamp,
    };
  }

  public calculateVideoTime(state: VideoVariableValueType): number {
    let time = state.videoTime + (state.isPlaying ? Math.max(0, this.timestamp - (state.localTime || 0)) : 0);
    if (this.controller.element && this.controller.element.duration) {
      time %= this.controller.element.duration;
    }
    return time;
  }

  // TODO: move to variable ?
  public setState(state: VideoVariableValueType) {
    let promise = Promise.resolve();
    if (state.source
      && (this.source?.ogg !== state.source.ogg || this.source?.mp4 !== state.source.mp4)
    ) {
      promise = this.setSource(state.source);
    }
    return (promise || Promise.resolve())
      .then(() => {
        return this.controller.toggle(state.isPlaying);
      })
      .then(() => {
        if (state.videoTime <= 0) return;
        const videoTime = this.calculateVideoTime(state);
        this.controller.setTime(videoTime);
      })
      .then(() => {
        this.prevVideoState = state;
      });
  }

  public get variable(): VideoVariable | undefined {
    return this.entity.getComponent(NetworkObjectComponent)?.netObject?.getVariableByName('video') as VideoVariable;
  }

  public play() : Promise<void> {
    return this.controller.toggle(true).then(() => {
      this.updateMaterial();
      this.variable?.setNeedUpdateFromLocal();
    });
  }

  public pause() {
    return this.controller.toggle(false).then(() => {
      this.updateMaterial();
      this.variable?.setNeedUpdateFromLocal();
    });
  }

  public toggleSound() {
    this.controller.toggleSound();
  }

  public toggle() {
    return this.controller.isPlaying() ? this.pause() : this.play();
  }

  protected getMeshFromEntity(): Three.Mesh | null {
    let mesh: Three.Mesh | null = null;
    this.entity.traverse((obj) => {
      if (mesh) return;
      if (obj instanceof Three.Mesh) {
        mesh = obj;
      }
    });
    return mesh;
  }

  public setSource(source: VideoSourceType, skipUpdate = false) {
    if (!this.enabled) return Promise.resolve();
    this.enabled = false;
    this.source = { ...source };
    const promise = this.controller.setSource(source, skipUpdate);
    return (promise || Promise.resolve()).then(() => {
      if (!skipUpdate) this.updateMaterial();
      this.enabled = true;
    });
  }

  public setUpTexture() {
    if (!this.targetMesh) return;
    if (!this.controller.element) return;
    const videoTexture = new Three.VideoTexture(this.controller.element);
    videoTexture.flipY = false;
    this.entity.app.renderer.initTexture(videoTexture);
    this.targetMesh.material = new Three.MeshBasicMaterial({
      map: videoTexture,
      side: Three.FrontSide,
      toneMapped: false,
    });
    this.updateMaterial();

    forDistRayCast.forEach((item) => {
      const itemMesh = this.entity.app.sceneManager.currentThreeScene?.getObjectByName(item);
      if (itemMesh instanceof Three.Mesh) itemMesh.material.side = Three.DoubleSide;
      this.raycastArray.push(itemMesh);
    });
  }

  public updateMaterial() {
    if (!this.targetMesh) return;
    const material = this.targetMesh.material as Three.MeshBasicMaterial;
    if (!material) return;
    material.needsUpdate = true;
    if (material.map) {
      material.needsUpdate = true;
    }
  }

  public changeVolume(generalPosition: number, blocked: boolean) {
    const position = generalPosition < 0 ? -generalPosition : generalPosition;
    if (blocked) {
      if (this.controller.element) this.controller.element.volume = 0;
    } else {
      if (generalPosition < this.distanceVolume && generalPosition > -this.distanceVolume) {
        if (this.controller.element) this.controller.element.volume = 1 - (position / this.distanceVolume);
      }
      if (position > this.distanceVolume) {
        if (this.controller.element) this.controller.element.volume = 0;
      }
    }
  }
}
