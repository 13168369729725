import * as EmptyVideo from './EmptyVideo';

export type VideoSourceType = {
  mp4: string;
  ogg: string;
};

export class VideoController {
  public element: HTMLVideoElement | null = null;

  protected handleLoadedData: () => void = () => {};

  public createElement(appendTo: HTMLElement = document.body): Promise<HTMLVideoElement | null> {
    this.element = document.createElement('video');
    this.element.autoplay = false;
    this.element.controls = true;
    this.element.muted = false;
    this.element.playsInline = true;
    this.element.loop = true;
    this.element.preload = 'none';
    this.element.style.display = 'none';
    // this.element.style.position = 'absolute';
    // this.element.style.width = '200px';
    // this.element.style.height = '100px';
    // this.element.style.top = `${index * 100}px`;
    // this.element.setAttribute('id', `vid-${index}`);
    // index += 1;
    appendTo.appendChild(this.element);

    ['mp4', 'ogg', 'webm'].forEach((type) => {
      const source = document.createElement('source');
      source.src = EmptyVideo[type as keyof typeof EmptyVideo];
      source.type = `video/${type}`;
      this.element?.appendChild(source);
    });

    return this.update().then(() => {
      if (!this.element) return Promise.resolve(null);
      return this.element.play().then(() => {
        if (this.element) this.element.innerHTML = '';
        return this.element;
      });
    });
  }

  public isPlaying(): boolean {
    if (!this.element) return false;
    return this.element.currentTime > 0
      && !this.element.paused && !this.element.ended
      && this.element.readyState >= this.element.HAVE_CURRENT_DATA;
  }

  public toggle(isPlaying: boolean): Promise<void> {
    if (!this.element) return Promise.resolve();
    if (isPlaying && !this.isPlaying()) {
      return this.element.play();
    }
    if (!isPlaying && this.isPlaying()) {
      // this.mute();
      this.element.pause();
    }
    return Promise.resolve();
  }

  public mute() {
    if (!this.element) return;
    this.element.muted = true;
  }

  public unmute() {
    if (!this.element) return;
    this.element.muted = false;
  }

  public toggleSound() {
    if (!this.element) return;
    if (this.element.muted) this.unmute();
    else this.mute();
  }

  public get time(): number {
    if (!this.element) return 0;
    return this.element.currentTime;
  }

  public setTime(time: number) {
    if (!this.element) return 0;
    this.element.currentTime = time;
  }

  public setSource({ mp4, ogg }: VideoSourceType, skipUpdate = false) {
    if (!this.element) return Promise.resolve();
    if (this.isPlaying()) this.element.pause();
    this.element.currentTime = 0;
    this.element.innerHTML = '';
    [{ type: 'mp4', src: mp4 }, { type: 'ogv', src: ogg }].forEach((({ type, src }) => {
      const source = document.createElement('source');
      source.src = src;
      source.type = `video/${type}`;
      this.element?.appendChild(source);
    }));
    this.element.playsInline = true;
    this.element.loop = true;
    return skipUpdate ? Promise.resolve() : this.update();
  }

  public update() {
    if (!this.element) return Promise.resolve();
    this.element.load();
    return new Promise<void>((resolve) => {
      this.element?.removeEventListener('loadeddata', this.handleLoadedData);
      this.element?.addEventListener('loadeddata', this.handleLoadedData = () => {
        this.setTime(0.1);
        resolve();
      }, false);
    });
  }

  public setVolume(value: number) {
    if (!this.element) return;
    this.element.volume = value;
  }
}
