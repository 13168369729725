import React, {FC, memo, useMemo, useState} from 'react';
import classNames from './RightSideComponent.module.css';
import IconButton from '../../../components/IconButton/IconButton';
import UnionModal from '../../UnionModal/UnionModal';
import useParticipants from '../../../hooks/audio/useParticipants';
import { devices } from '../../../types/devices';

export interface RightSideComponentProps {
  onUnionClick?: () => void;
  modalUserName: string;
  roomId: string;
  onCloseUnionModal: () => void;
  onThrowStuff: () => void;
  isOpen: boolean;
  isAdmin: boolean;
  onHappy: () => void;
  isSeat: boolean;
  isConference: boolean;
  device: string;
  onChangeAvatar: () => void;
  isClickAvatar: boolean;
}

const RightSideComponent: FC<RightSideComponentProps> = memo((props) => {
  const {
    onUnionClick = () => undefined,
    onCloseUnionModal = () => undefined,
    onThrowStuff = () => undefined,
    onHappy = () => undefined,
    onChangeAvatar = () => undefined,
    roomId,
    isSeat = false,
    isOpen = false,
    modalUserName = '',
    isConference = false,
    isClickAvatar = false,
    isAdmin,
    device,
  } = props;

  const root = useMemo(() => classNames[`root_${device}`], [device]);

  const { participants } = useParticipants();

  return (
    <div>
      <div className={root}>
        <IconButton
          size={device === devices.mobile ? 'Xs' : 'Md'}
          isActive={isClickAvatar}
          onCLick={onChangeAvatar}
          icon="change"
        />
        <IconButton
          size={device === devices.mobile ? 'Xs' : 'Md'}
          disabled={!isConference}
          isActive={isOpen}
          onCLick={onUnionClick}
          icon="union"
        />
        <UnionModal
          isAdmin={isAdmin}
          modalUserName={modalUserName}
          roomId={roomId}
          participants={participants}
          onClose={onCloseUnionModal}
          isOpen={isOpen}
        />
      </div>
      {device === devices.mobile && <div className={classNames.handBlock}>
        <IconButton
          size={device === devices.mobile ? 'Xs' : 'Md'}
          isActive={isOpen}
          onCLick={onThrowStuff}
          icon="hand"
        />
        {!isSeat && <IconButton
          size={device === devices.mobile ? 'Xs' : 'Md'}
          isActive={isOpen}
          onCLick={onHappy}
          icon="happy"
        />}
        {isSeat && <IconButton
          id="seatButton"
          size={device === devices.mobile ? 'Xs' : 'Md'}
          isActive={isOpen}
          icon="arrowDown"
        />}
      </div>}
    </div>
  );
});

export default RightSideComponent;
