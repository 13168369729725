export type Connection_id = string;

export interface Ws_config {
  url: string | URL;
  protocols?: string | string[];
}

export class Pc_config implements RTCConfiguration {
  iceServers: RTCIceServer[] = [{urls: ['stun:stun.l.google.com:19302']}];
}

export class Dc_config implements RTCDataChannelInit {
  label: string;
  ordered: boolean = false;
  maxPacketLifeTime: number = 100;

  constructor(label: string) {
    this.label = label;
  }
}

export class Connection_config {
  ws: Ws_config;
  dc: Dc_config;
  pc: Pc_config = new Pc_config();
  verbose_logs: boolean = false;

  constructor(ws: Ws_config, dc: Dc_config, pc: Pc_config = new Pc_config(), verbose_logs: boolean = false) {
    this.ws = ws;
    this.pc = pc;
    this.dc = dc;
    this.verbose_logs = verbose_logs;
  }

}

export class Connection {
  private _id!: Connection_id;
  private _ws!: WebSocket;
  private _pc!: RTCPeerConnection;
  private _dc!: RTCDataChannel;
  private _on_ws_message?: (message: any) => any;
  private _on_dc_message?: (message: any) => any;
  private _verbose_logs: boolean = false;

  static create(conf: Connection_config): Promise<Connection> {
    const connection = new Connection();
    connection._verbose_logs = conf.verbose_logs;
    connection._ws = new WebSocket(conf.ws.url, conf.ws.protocols);
    let creationAnswerReceived = false;
    const awaitingCandidates: any[] = [];
    connection._ws.onmessage = (event) => {
      connection.log_debug({e: event.data});
      try {
        const message = JSON.parse(event.data);
        if (message.action === "peerconnection-creation-new-candidate") {
          if (!creationAnswerReceived) {
            awaitingCandidates.push(message);
          } else {
            const res = connection._pc.addIceCandidate({
              candidate: message.payload.candidate,
              sdpMid: message.payload.mid,
            });
            res
              .then(e => {
                connection.log_debug({success: e, pl: message});
                return e;
              })
              .catch(e => {
                console.error({error: e, pl: message})
              });
          }
          return;
        } else if (message.action === "peerconnection-creation-answer") {
          connection.log_debug("remote desc");
          connection._id = message.payload.connection_id;
          connection._pc.setRemoteDescription({
            sdp: message.payload.sdp,
            type: message.payload.type,
          });
          creationAnswerReceived = true;
          if (awaitingCandidates.length) {
            for (const candidate of awaitingCandidates) {
              connection._pc.addIceCandidate({
                candidate: candidate.payload.candidate,
                sdpMid: candidate.payload.mid
              });
            }
          }
          return;
        }
      } catch (err) {
        connection.log_debug(err);
      }
      try {
        if (connection._on_ws_message) {
          connection._on_ws_message(event.data);
        }
      } catch (err) {
        connection.log_debug(err);
      }
    };
    connection._ws.onerror = (event) => {
      console.error(event);
    };
    connection._ws.onclose = (event) => {
      connection.log_debug("Closing ws");
      console.log("Closing ws");
      connection.close();
    };
    const ws_opened = new Promise<void>((resolve) => {
      connection._ws.onopen = (event) => {
        resolve();
      };
    });

    connection._pc = new RTCPeerConnection(conf.pc);
    connection._pc.onicecandidate = (event) => {
      if (event.candidate && connection._ws) {
        connection._ws.send(JSON.stringify({
          action: "peerconnection-creation-new-candidate",
          payload: {
            candidate: event.candidate,
          }
        }));
      }
    };
    connection._pc.onicecandidateerror = (event) => {
      console.error(event);
    };
    connection._pc.oniceconnectionstatechange = (event) => {
      connection.log_debug("oniceconnectionstatechange", connection._dc);
      connection.log_debug(event);
    };
    connection._pc.onconnectionstatechange = (event) => {
      connection.log_debug("onconnectionstatechange", connection._dc);
      connection.log_debug(event);
    };
    connection._pc.onsignalingstatechange = (event) => {
      connection.log_debug("onsignalingstatechange", connection._dc);
      connection.log_debug(event);
    };
    // connection._pc.onnegotiationneeded = async (event) => {
    //     connection.log_debug("onnegotiationneeded");
    //     try {
    //         const offer = await connection._pc.createOffer();
    //         if (connection._pc.signalingState !== "stable") {
    //             connection.log_debug("Connection still unstable");
    //             return;
    //         }
    //
    //         await connection._pc.setLocalDescription(offer);
    //
    //         connection.send_ws(JSON.stringify({
    //             action: "peerconnection-creation-offer",
    //             payload: {
    //                 sdp: offer.sdp,
    //                 type: offer.type,
    //             }
    //         }));
    //     } catch (err) {
    //         console.error(err);
    //     }
    // }

    connection._dc = connection._pc.createDataChannel(conf.dc.label, conf.dc as RTCDataChannelInit);
    connection._dc.onmessage = (event) => {
      try {
        if (connection._on_dc_message) {
          connection._on_dc_message(event.data);
        }
      } catch (err) {
        console.error(err);
      }

    };
    connection._dc.onerror = (event) => {
      console.error(event);
    };
    // connection._dc.onclosing
    connection._dc.onclose = (event) => {
      connection.log_debug("Closing dc");
      console.log("Closing dc");
      connection.close();
    }
    // connection._dc.onbufferedamountlow

    return Promise.all([
      ws_opened.then(() => {
        return connection._pc.createOffer();
      }).then((offer) => {
        connection.log_debug("Created offer");
        return connection._pc.setLocalDescription(offer);
      }).then(() => {
        const offer = connection._pc.localDescription;
        if (offer === null) {
          throw new Error("Unable to create offer for RTCPeerConnection");
        }
        connection.log_debug("Send offer");
        connection._ws.send(JSON.stringify({
          action: "peerconnection-creation-offer",
          payload: {
            sdp: offer.sdp,
            type: offer.type,
          },
        }));
      }),
      new Promise<void>((resolve) => {
        connection._dc.onopen = (event) => {
          connection.log_debug("dc opened")
          resolve();
        };
      })]).then(() => {
      return connection;
    });
  }

  get id() {
    return this._id;
  }

  close() {
    this._ws.close();
    this._dc.close();
    this._pc.close();
  }

  on_ws_message(listener: (message: any) => any) {
    this._on_ws_message = listener;
  }

  on_dc_message(listener: (message: any) => any) {
    this._on_dc_message = listener;
  }

  send_ws(data: string | ArrayBuffer | Blob | ArrayBufferView) {
    this._ws.send(data);
  }

  send_dc(data: string | ArrayBuffer | Blob | ArrayBufferView) {
    // @ts-ignore
    this._dc.send(data);
  }

  private log_debug(message?: any, ...optionalParams: any[]): void {
    if (this._verbose_logs) {
      console.log(message, ...optionalParams);
    }
  }
}
