class CreateVideo {
  constructor(obj, component) {
    this.obj = obj;
    this.video = component.controller.element;
    this.controller = component.controller;
    this.compoent = component;
    this.obj.addedVideo = this;
    this.obj.showController = false;
  }

  set(video_info) {
    // this.video = this.controller.createElement();
    this.video = this.compoent.controller.element;
    this.controller.setSource({ mp4: video_info[0], ogg: video_info[1] });
    return this.video;
  }

  update(video_info) {
    console.log(video_info[0], video_info[1]);
    this.compoent.setSource({ mp4: video_info[0], ogg: video_info[1] });
    this.compoent.variable?.setNeedUpdateFromLocal();
    return this.video;
  }

  play() {
    this.compoent.play();
  }

  pause() {
    this.compoent.pause();
  }
}

export { CreateVideo };
