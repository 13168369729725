import { Connection, Connection_config, Connection_id } from './Connection';

export type Room_id = string;

export interface On_connection_opened_data {
  action: 'connection-opened';
  payload: {
    connection_ids: Connection_id[];
  };
}

export interface On_connection_joined_data {
  action: 'connection-joined';
  payload: {
    connection_id: Connection_id;
  };
}

export interface On_connection_left_data {
  action: 'connection-left';
  payload: {
    connection_id: Connection_id;
  };
}

export interface On_message_data {
  action: 'message-ws' | 'message-dc' | 'server-broadcast-ws' | 'server-broadcast-dc' | 'room-broadcast-ws' | 'room-broadcast-dc';
  send_time: number;
  payload: {
    sender: Connection_id;
    message: any;
  };
}

export interface On_list_connections_data {
  action: 'list-connections';
  payload: {
    connection_ids: Connection_id[];
  };
}

export interface On_room_created_data {
  action: 'room-created';
  payload: {
    room_id: Room_id;
  };
}

export interface On_room_closed_data {
  action: 'room-closed';
  payload: {
    room_id: Room_id;
  };
}

export interface On_joined_room_data {
  action: 'joined-room';
  payload: {
    room_id: Room_id;
    connection_id: Connection_id;
  };
}

export interface On_left_room_data {
  action: 'joined-room';
  payload: {
    room_id: Room_id;
    connection_id: Connection_id;
  };
}

export interface On_list_rooms_data {
  action: 'list-rooms';
  payload: {
    room_ids: Room_id[];
  };
}

export interface On_list_room_connections_data {
  action: 'list-room-connections';
  payload: {
    room_id: Room_id;
    connection_ids: Connection_id[];
  };
}

export interface On_error_data {
  action: 'error';
  payload: {
    type: string;
    action: string;
    description: string;
  };
}

export class Client {
  private _connection: Connection;

  private _room_id?: Room_id;

  private constructor(connection: Connection) {
    this._connection = connection;
    const process_ws_message = (message: any) => {
      if (message.action === 'connection-joined') {
        this.on_connection_joined?.(message);
      } else if (message.action === 'connection-opened') {
        this.on_connection_opened?.(message);
      } else if (message.action === 'connection-left') {
        this.on_connection_left?.(message);
      } else if (
        message.action === 'message-ws'
        || message.action === 'server-broadcast-ws'
        || message.action === 'room-broadcast-ws'
      ) {
        this.on_ws_message?.(message);
      } else if (
        message.action === 'message-dc'
        || message.action === 'server-broadcast-dc'
        || message.action === 'room-broadcast-dc'
      ) {
        this.on_dc_message?.(message);
      } else if (message.action === 'list-connections') {
        this.on_list_connections?.(message);
      } else if (message.action === 'room-created') {
        this.on_room_created?.(message);
      } else if (message.action === 'room-closed') {
        const room_closed_message = message as On_room_closed_data;
        if (this._room_id === room_closed_message.payload.room_id) {
          this._room_id = undefined;
        }
        this.on_room_closed?.(message);
      } else if (message.action === 'joined-room') {
        const joined_room_message = (message as On_joined_room_data);
        if (this.id === joined_room_message.payload.connection_id) {
          this._room_id = joined_room_message.payload.room_id;
        }
        this.on_joined_room?.(message);
      } else if (message.action === 'left-room') {
        this.on_left_room?.(message);
      } else if (message.action === 'list-rooms') {
        this.on_list_rooms?.(message);
      } else if (message.action === 'list-room-connections') {
        this.on_list_room_connections?.(message);
      } else if (message.action === 'error') {
        this.on_error?.(message);
      }
    };
    this._connection.on_ws_message((data) => {
      const message = JSON.parse(data);
      if (message.action === 'message-batch') {
        for (const msg of message.payload.messages) {
          const sub_message = JSON.parse(msg);
          process_ws_message(sub_message);
        }
      } else {
        process_ws_message(message);
      }
    });
    const process_dc_message = (message: any) => {
      if (
        message.action === 'message-ws'
        || message.action === 'server-broadcast-ws'
        || message.action === 'room-broadcast-ws'
      ) {
        this.on_ws_message?.(message);
      } else if (
        message.action === 'message-dc'
        || message.action === 'server-broadcast-dc'
        || message.action === 'room-broadcast-dc'
      ) {
        this.on_dc_message?.(message);
      }
    };
    this._connection.on_dc_message((data) => {
      const message = JSON.parse(data);
      if (message.action === 'message-batch') {
        for (const msg of message.payload.messages) {
          const sub_message = JSON.parse(msg);
          process_dc_message(sub_message);
        }
      } else {
        process_dc_message(message);
      }
    });
  }

  get id() {
    return this._connection.id;
  }

  get room_id() {
    return this._room_id;
  }

  static create(conf: Connection_config): Promise<Client> {
    return Connection.create(conf).then((connection: Connection) => {
      return new Client(connection);
    });
  }

  on_connection_opened?: (data: On_connection_opened_data) => any;

  on_connection_joined?: (data: On_connection_joined_data) => any;

  list_connections() {
    this._connection.send_ws(JSON.stringify({
      action: 'list-connections',
      payload: {},
    }));
  }

  on_list_connections?: (data: On_list_connections_data) => any;

  close() {
    this._connection.close();
  }

  on_connection_left?: (data: On_connection_left_data) => any;

  message_ws(connections: Connection_id[], message: any) {
    if (connections.length === 0) {
      return;
    }
    this._connection.send_ws(JSON.stringify({
      action: 'message-ws',
      payload: {
        recipients: connections,
        message,
      },
    }));
  }

  message_dc(connections: Connection_id[], message: any) {
    if (connections.length === 0) {
      return;
    }
    this._connection.send_dc(JSON.stringify({
      action: 'message-dc',
      payload: {
        recipients: connections,
        message,
      },
    }));
  }

  server_broadcast_ws(message: any) {
    this._connection.send_ws(JSON.stringify({
      action: 'server-broadcast-ws',
      payload: {
        message,
      },
    }));
  }

  server_broadcast_dc(message: any) {
    this._connection.send_dc(JSON.stringify({
      action: 'server-broadcast-dc',
      payload: {
        message,
      },
    }));
  }

  on_ws_message?: (data: On_message_data) => any;

  on_dc_message?: (data: On_message_data) => any;

  create_room() {
    this._connection.send_ws(JSON.stringify({
      action: 'create-room',
      payload: {},
    }));
  }

  on_room_created?: (data: On_room_created_data) => any;

  close_room() {
    if (!this._room_id) {
      throw "Can't close undefined room";
    }
    this._connection.send_ws(JSON.stringify({
      action: 'close-room',
      payload: {
        room_id: this._room_id,
      },
    }));
  }

  on_room_closed?: (data: On_room_closed_data) => any;

  join_room(room_id: Room_id) {
    // TODO: make server handle this
    // if (this._room_id) {
    //     this.leave_room();
    // }
    this._connection.send_ws(JSON.stringify({
      action: 'join-room',
      payload: {
        room_id,
      },
    }));
  }

  on_joined_room?: (data: On_joined_room_data) => any;

  leave_room() {
    this._connection.send_ws(JSON.stringify({
      action: 'leave-room',
      payload: {
        room_id: this._room_id,
      },
    }));
    this._room_id = undefined;
  }

  on_left_room?: (data: On_left_room_data) => any;

  room_broadcast_ws(message: any) {
    if (!this._room_id) {
      return false;
    }
    this._connection.send_ws(JSON.stringify({
      action: 'room-broadcast-ws',
      payload: {
        room_id: this._room_id,
        message,
      },
    }));
    return true;
  }

  room_broadcast_dc(message: any) {
    if (!this._room_id) {
      return false;
    }
    this._connection.send_dc(JSON.stringify({
      action: 'room-broadcast-dc',
      payload: {
        room_id: this._room_id,
        message,
      },
    }));
  }

  list_rooms() {
    this._connection.send_ws(JSON.stringify({
      action: 'list-rooms',
      payload: {},
    }));
  }

  on_list_rooms?: (data: On_list_rooms_data) => any;

  list_room_connections(room_id: Room_id) {
    this._connection.send_ws(JSON.stringify({
      action: 'list-room-connections',
      payload: { room_id },
    }));
  }

  on_list_room_connections?: (data: On_list_room_connections_data) => any;

  on_error?: (data: On_error_data) => any;
}
