import {
  ChangeEvent, FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { motion } from 'framer-motion';
import { Box, Modal } from '@mui/material';
import Fade from '@mui/material/Fade';
import { useWindowSize } from 'react-use';
import Card from '../../components/Card/Card';
import classNames from './UserModal.module.css';
import Girl from '../../assets/image/render_girl.png';
import Boy from '../../assets/image/render_boy.png';
import Input from '../../components/Input/Input';
import useMediaQuery from '../../hooks/useMediaQuery';
import { GenderEnum } from '../../enum/GenderEnum';

export interface UserModalProps {
  isOpen: boolean;
  name: string;
  setNameHandler: (value: string) => void;
  onClick?: () => void;
  onClickGender: (name: GenderEnum) => void;
  onClose?: (event: Event, reason: string) => void;
}

const UserModal: FC<UserModalProps> = (props) => {
  const {
    isOpen,
    name,
    setNameHandler = () => undefined,
    onClickGender = () => undefined,
    onClick = () => undefined,
    onClose = () => undefined,
  } = props;

  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    const interval = setTimeout(() => {
      setIsDisable(false);
    }, 4000);
    return () => {
      clearTimeout(interval);
    };
  }, []);

  const changeNameHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNameHandler(e.target.value);
  }, []);

  const { width } = useWindowSize();

  const styles = useMemo(() => {
    const disable = isDisable && classNames.disable;
    return [
      classNames.button,
      disable,
    ].join(' ');
  }, [isDisable]);

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
    >
      <Fade in={isOpen}>
        <Box
          id="modal"
          component={motion.div}
          animate={isOpen ? 'open' : 'closed'}
        >
          <div className={classNames.root}>
            <div className={classNames.header}>
              Pick an Avatar
            </div>
            <div className={classNames.header2}>
              You’ll be able to customize it a little bit later
            </div>
            <Input
              value={name}
              onChange={changeNameHandler}
              width="100%"
              placeholder="User_Name"
            />
            <div className={classNames.gender}>
              <Card
                onClick={() => onClickGender(GenderEnum.FEMALE)}
                url={Girl}
                height={width < 1100 ? '126px' : '243px'}
              />
              <Card
                height={width < 1100 ? '126px' : '243px'}
                onClick={() => onClickGender(GenderEnum.MALE)}
                url={Boy}
              />
            </div>
            <div className={classNames.scopeButton}>
              <button
                disabled={isDisable}
                onClick={() => {
                  if (!isDisable) onClick();
                  setIsDisable(true);
                  setTimeout(() => {
                    setIsDisable(false);
                  }, 1200);
                }}
                type="button"
                className={styles}
              >
                {isDisable ? 'loading' : 'start'}
              </button>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UserModal;
