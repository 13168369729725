import EventEmitter from 'eventemitter3';
import {
  Client,
  On_room_created_data as OnRoomCreatedData,
  On_joined_room_data as OnJoinedRoomData,
  On_error_data as OnErrorData,
  On_list_room_connections_data as OnListRoomConnectionsData,
  On_left_room_data as OnLeftRoomData,
  On_room_closed_data as OnRoomClosedData,
  On_message_data as OnMessageData,
} from '../../Pluto';
import * as Pluto from '../../Pluto';
import {
  NetworkId,
  OnJoinedRoomPayload, OnLeftRoomPayload,
  OnListRoomConnectionsPayload, OnRoomClosedPayload,
  OnRoomCreatedPayload,
  Transport,
  TransportEventTypes,
} from './types';
import {Connection_config} from "../../Pluto/Connection";

export default class TransportPlutoNeo implements Transport {
  public client: Client;

  public config: Connection_config;

  public networkId: NetworkId;

  public emptyError = { action: '', description: '', type: '', time: 0 };

  public lastError = { action: '', description: '', type: '', time: 0 };

  public currentRoomId = '';

  protected _events: EventEmitter<TransportEventTypes> = new EventEmitter<TransportEventTypes>();

  constructor(client: Client, config: Connection_config) {
    this.client = client;
    this.config = config;
    this.networkId = String(client.id);
    this.initEvents();
  }

  public reconnect() {
    return this.closeSession(false).then(() => Pluto.Client.create(this.config))
      .then((client) => {
        this.client = client;
        this.networkId = String(client.id);
        this.initEvents();
      });
  }

  public initEvents() {
    this.client.on_ws_message = this.onMessage.bind(this);
    this.client.on_dc_message = this.onData.bind(this);
    this.client.on_room_created = this.onRoomCreated.bind(this);
    this.client.on_joined_room = this.onJoinedRoom.bind(this);
    this.client.on_error = this.onError.bind(this);
    this.client.on_list_room_connections = this.onListRoomConnections.bind(this);
    this.client.on_left_room = this.onLeftRoom.bind(this);
    this.client.on_room_closed = this.onCloseRoom.bind(this);
  }

  public get events(): EventEmitter<TransportEventTypes> {
    return this._events;
  }

  public isError() {
    return !!this.lastError.type;
  }

  public clearError() {
    this.lastError = { ...this.emptyError };
  }

  protected requestWithErrorCheck<PromiseValueType>(request: Promise<PromiseValueType>, timeout = 10000) {
    return new Promise<PromiseValueType>((resolve, reject) => {
      this.clearError();
      const timoutId = setTimeout(() => {
        const message = this.isError() ? this.lastError.description : `Request timeout ${timeout}ms`;
        reject(new Error(message));
      }, timeout);
      return request.then((arg) => {
        clearTimeout(timoutId);
        resolve(arg);
      });
    });
  }

  public closeSession(closeRoom = true) {
    let promise: Promise<void | OnRoomClosedPayload> = Promise.resolve();
    if (closeRoom) {
      promise = this.listRoomConnections().then(({ connectionIds }) => {
        if (connectionIds.length === 1 && connectionIds[0] === this.networkId) this.closeRoom();
      });
    }
    return promise
      .then(() => this.leaveRoom())
      .then(() => this.closeConnection());
  }

  public closeConnection() {
    this.client.close();
  }

  public createOrJointRoom(roomId: NetworkId | null, timeout = 3000) {
    const promise = roomId ? Promise.resolve({ roomId }) : this.createRoom();
    return this.requestWithErrorCheck(
      promise.then(({ roomId: createdRoomId }) => this.joinRoom(createdRoomId)),
    );
    // return new Promise<{ roomId: NetworkId }>((resolve, reject) => {
    //   const timoutId = setTimeout(() => {
    //     reject(new Error('Try connect to closed room or network problems'));
    //   }, timeout);
    //   const promise = roomId ? Promise.resolve({ roomId }) : this.createRoom();
    //   return promise.then(({ roomId: createdRoomId }) => this.joinRoom(createdRoomId).then(() => {
    //     clearTimeout(timoutId);
    //     return resolve({ roomId: createdRoomId });
    //   }));
    // });
  }

  public createRoom() {
    // TODO: onError
    return new Promise<OnRoomCreatedPayload>((resolve, reject) => {
      this.events.once('onRoomCreated', (data) => {
        resolve(data);
      });
      this.client.create_room();
    });
  }

  public closeRoom() {
    return new Promise<OnRoomClosedPayload>((resolve, reject) => {
      this.events.once('onRoomClosed', (data) => resolve(data));
      this.client.close_room();
    });
  }

  public joinRoom(roomId: NetworkId) {
    // TODO: onError
    return new Promise<OnJoinedRoomPayload>((resolve, reject) => {
      const onJoin = ({ roomId: createdRoomId, clientId } : OnJoinedRoomPayload) => {
        if (createdRoomId !== roomId || clientId !== this.networkId) {
          console.log('wrong room');
          // TODO: error?
          return;
        }
        this.events.off('onJoinedRoom', onJoin);
        return resolve({ roomId, clientId });
      };
      this.events.on('onJoinedRoom', onJoin);
      this.client.join_room(roomId);
    });
  }

  // TODO: why only current room?
  public listRoomConnections(roomId: NetworkId | null = null): Promise<OnListRoomConnectionsPayload> {
    const id = roomId || this.client.room_id;
    if (!id) return Promise.resolve({ roomId: '', connectionIds: [] });
    return new Promise<OnListRoomConnectionsPayload>((resolve) => {
      this.events.once('onListRoomConnections', (data) => {
        resolve(data);
      });
      this.client.list_room_connections(id);
    });
  }

  // TODO: list_rooms

  public leaveRoom() {
    const roomId = this.client.room_id;
    const connectionId = this.client.id;
    if (!roomId) return Promise.resolve({ roomId: '', connectionId });
    return new Promise<OnLeftRoomPayload>((resolve) => {
      const handler = setInterval(() => {
        this.listRoomConnections(roomId).then((data: OnListRoomConnectionsPayload) => {
          if (data.connectionIds.indexOf(connectionId) < 0) {
            clearInterval(handler);
            resolve({ roomId, connectionId });
          }
        });
      }, 500);
      // onLeftRoom -- no events ????
      // const onLeftRoom = (data: OnLeftRoomPayload) => {
      //   console.log('!!!!!!!!!!', data);
      //   if (data.roomId === roomId && data.connectionId === connectionId) {
      //     resolve(data);
      //     this.events.off('onLeftRoom', onLeftRoom);
      //   }
      // };
      // this.events.on('onLeftRoom', onLeftRoom);
      this.client.leave_room();
    });
  }

  public onCloseRoom(data: OnRoomClosedData) {
    return this.events.emit('onRoomClosed', { roomId: data.payload.room_id });
  }

  public onLeftRoom(data: OnLeftRoomData) {
    return this.events.emit('onLeftRoom', {
      roomId: data.payload.room_id,
      connectionId: data.payload.connection_id,
    });
  }

  public onListRoomConnections(data: OnListRoomConnectionsData) {
    return this.events.emit('onListRoomConnections', {
      roomId: data.payload.room_id,
      connectionIds: data.payload.connection_ids,
    });
  }

  public onRoomCreated(data: OnRoomCreatedData) {
    return this.events.emit('onRoomCreated', { roomId: data.payload.room_id });
  }

  public onJoinedRoom(data: OnJoinedRoomData) {
    // console.log('onJoinedRoom', data);
    return this.events.emit('onJoinedRoom', { roomId: data.payload.room_id, clientId: data.payload.connection_id });
  }

  public onError(data: OnErrorData) {
    console.log('onError');
    console.warn(data);
    this.lastError = {
      type: data.payload.type,
      action: data.payload.action,
      description: data.payload.description,
      time: Date.now(),
    };
    this.events.emit('onError', {
      ...data.payload,
    });
  }

  public onMessage(data: OnMessageData) {
    // console.log(data.send_time, Date.now() - data.send_time);
    // console.log('onMessage', data);
    this.events.emit('onMessage', {
      serverSendTime: data.send_time,
      message: data.payload.message,
      sender: data.payload.sender,
    });
  }

  public onData(data: OnMessageData) {
    // console.log(data.send_time, Date.now() - data.send_time);
    // console.log('onData', data);
    this.events.emit('onData', {
      serverSendTime: data.send_time,
      message: data.payload.message,
      sender: data.payload.sender,
    });
  }

  public sendMessageInRoom(message: any) {
    // console.log('sendMessageInRoom', message, this.client.room_id);
    return this.client.room_broadcast_ws(message);
  }

  public sendDataInRoom(message: any) {
    return this.client.room_broadcast_dc(message);
  }

  public sendMessageInServer(message: any) {
    // console.log('sendMessageInServer', message, this.client.room_id);
    return this.client.server_broadcast_ws(message);
  }

  public sendDataInServe(message: any) {
    return this.client.server_broadcast_dc(message);
  }

  public sendMessageTo(clients: NetworkId[], message: any) {
    this.client.message_ws(clients, message);
  }

  public sendDataTo(clients: NetworkId[], message: any) {
    this.client.message_dc(clients, message);
  }
}
